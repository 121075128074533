import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import {
  AdminPanelSettings,
  BarChart,
  Business,
  DeveloperBoard,
  Download,
  Engineering,
  Handyman,
  History,
  Notifications,
  Person,
  Tune,
} from "@mui/icons-material";
import {
  azMaintenanceUrl,
  elevatorListToChartUrl,
  maintenanceListUrl,
  roles,
  routes,
} from "../costants";
import CustomButton from "../../custom/CustomButton";
import { lightColor } from "../../custom/utils";

type HomePageProps = {
  role: string;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const HomePage: React.FC<HomePageProps> = ({ role, setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Typography color="#ffffff" key="1">
        {t("home")}
      </Typography>,
    ]);
  }, []);

  const handleRoleIconCards = () => {
    switch (role) {
      case roles.superadmin:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("companyOverview")],
            link: [routes.companyListToChart],
          },
          {
            img: (
              <AdminPanelSettings
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("createAdmin"), t("updateAdmin"), t("assignBoardType")],
            link: [
              routes.createAdmin,
              routes.adminList,
              routes.assignBoardType,
            ],
          },
        ];
      case roles.admin:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("companyOverview")],
            link: [routes.companyListToChart],
          },
          {
            img: (
              <Download
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("datas")],
            link: [routes.downloadData],
          },
          {
            img: (
              <Notifications
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("alarms")],
            link: [routes.alarms],
          },
          {
            img: (
              <Business
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("createCompany"), t("updateCompany")],
            link: [routes.createCompany, routes.companyListToUpdate],
          },
        ];
      case roles.installer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("elevatorOverview")],
            link: [elevatorListToChartUrl("all")],
          },
          {
            img: (
              <DeveloperBoard
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("createDevice"), t("deleteElevator")],
            link: [routes.createElevator, routes.deleteElevator],
          },
        ];
      case roles.customer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("elevatorOverview")],
            link: [elevatorListToChartUrl("all")],
          },
          {
            img: (
              <History
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("maintenanceHistory")],
            link: [routes.azUserMaintenance],
          },
        ];
      case roles.company:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("elevatorOverview")],
            link: [elevatorListToChartUrl("all")],
          },
          {
            img: (
              <DeveloperBoard
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("updateDeleteDevice")],
            link: [routes.elevatorListToUpdate],
          },
          {
            img: (
              <Download
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("datas")],
            link: [routes.downloadData],
          },
          {
            img: (
              <Notifications
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("alarms")],
            link: [routes.alarms],
          },
          {
            img: (
              <Person
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("createUser"), t("updateUser"), t("assignElevators")],
            link: [routes.createUsers, routes.usersList, routes.assignUser],
          },
          {
            img: (
              <Tune
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("updateParameters"), t("bufferBatteryTest")],
            link: [routes.paramsElevator, routes.testBatteriaTamponeElevator],
          },
        ];
      case roles.maintainer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("elevatorOverview")],
            link: [elevatorListToChartUrl("all")],
          },
          {
            img: (
              <Download
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("datas")],
            link: [routes.downloadData],
          },
          {
            img: (
              <Notifications
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("alarms")],
            link: [routes.alarms],
          },
          {
            img: (
              <Handyman
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("maintenances"), t("manutenzioni")],
            link: [maintenanceListUrl, azMaintenanceUrl],
          },
          {
            img: (
              <DeveloperBoard
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("updateParameters"), t("bufferBatteryTest")],
            link: [routes.paramsElevator, routes.testBatteriaTamponeElevator],
          },
        ];
      case roles.respMaintainer:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("elevatorOverview")],
            link: [elevatorListToChartUrl("all")],
          },
          {
            img: (
              <DeveloperBoard
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("updateDevice")],
            link: [routes.elevatorListToUpdate],
          },
          {
            img: (
              <Engineering
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [
              t("createMantainer"),
              t("updateMantainer"),
              t("assignElevators"),
            ],
            link: [
              routes.createMantainer,
              routes.mantainerList,
              routes.assignMantainer,
            ],
          },
          {
            img: (
              <Download
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("datas")],
            link: [routes.downloadData],
          },
          {
            img: (
              <Notifications
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("alarms")],
            link: [routes.alarms],
          },
          {
            img: (
              <History
                style={{
                  color: lightColor,
                  fontSize: "200px",
                }}
              />
            ),
            text: [t("maintenances"), t("manutenzioni")],
            link: [routes.maintenanceHistory, routes.azMaintenance],
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      {handleRoleIconCards().map((iconItem) => (
        <Grid item xs={12} md={6}>
          <CustomCard
            content={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>{iconItem.img}</Grid>
              </Grid>
            }
            actions={iconItem.text.map(
              (buttonText: string, buttonIndex: number) => (
                <>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    style={{ height: "65px" }}
                    onClick={() => navigate(iconItem.link[buttonIndex])}
                  >
                    {buttonText}
                  </Button>
                </>
              )
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
