import {
  CreateElevatorRequest,
  GetExportInfoRequest,
  UpdateElevatorRequest,
  updateAlarmsCalibrationRequest,
} from "../requests/elevatorService";
import {
  ZDMFetch,
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  getExportInfoUrl,
  createElevatorUrl,
  deleteElevatorUrl,
  getAlarmsUrl,
  getBoardTypeUrl,
  getCompanyElevatorsUrl,
  getContainersUrl,
  getElevatorUrl,
  getElevatorsUrl,
  sendJobUrl,
  updateElevatorUrl,
  updateAlarmsCalibrationUrl,
  getElevatorAlarmsUrl,
  getAlarmsCalibrationUrl,
  getLastDataUrl,
} from "./url";

export const createElevator = (body: CreateElevatorRequest) => {
  return verticalFetch(methodPost, createElevatorUrl, JSON.stringify(body));
};

export const getElevators = () => {
  return verticalFetch(methodGet, getElevatorsUrl);
};

export const getCompanyElevators = (id: string) => {
  return verticalFetch(methodGet, getCompanyElevatorsUrl(id));
};

export const getElevator = (id: string) => {
  return verticalFetch(methodGet, getElevatorUrl(id));
};

export const updateElevator = (r: UpdateElevatorRequest) => {
  return verticalFetch(methodPut, updateElevatorUrl(r.id), JSON.stringify(r));
};

export const deleteElevator = (id: string) => {
  return verticalFetch(
    methodDelete,
    deleteElevatorUrl,
    JSON.stringify({ installation_id: id })
  );
};

export const getBoardType = () => {
  return verticalFetch(methodGet, getBoardTypeUrl);
};

export const getContainers = () => {
  return verticalFetch(methodGet, getContainersUrl);
};

export const sendJob = (id: string, jobName: string, body: any) => {
  return ZDMFetch(
    methodPost,
    sendJobUrl(id, jobName),
    JSON.stringify({ value: body })
  );
};

export const getAlarms = (start: string, end: string) => {
  return verticalFetch(methodGet, getAlarmsUrl(start, end));
};

export const getElevatorAlarms = (id: string, end: boolean) => {
  return verticalFetch(methodGet, getElevatorAlarmsUrl(id, end));
};

export const updateAlarmsCalibration = (
  req: updateAlarmsCalibrationRequest
) => {
  return verticalFetch(
    methodPut,
    updateAlarmsCalibrationUrl(req.installation_id),
    JSON.stringify(req)
  );
};

export const getAlarmsCalibration = (id: string) => {
  return verticalFetch(methodGet, getAlarmsCalibrationUrl(id));
};

export const getExportInfo = (info: GetExportInfoRequest) => {
  return verticalFetch(methodGet, getExportInfoUrl(info));
};

export const getLastData = (id: string, tag: string, variable: string) => {
  return verticalFetch(methodGet, getLastDataUrl(id, tag, variable));
};
