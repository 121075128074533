import { isDev } from "../../components/pages/costants";
import {
  elevateFetch,
  methodPost,
  verticalFetch,
  methodGet,
} from "./httpRequests";
export const backendUrl = window._env_.API_ENDPOINT!;

//const elevateUrl = "https://www.adatec.it/apps/lifting/api.php";
const proxyAuthURL = `${backendUrl}/installations/elevate_data/proxy/auth`;
const proxyInstallationsURL = `${backendUrl}/installations/elevate_data/proxy/getInstallations`;
const elevateDataURL = (elevateId: string) =>
  `${backendUrl}/installations/elevate_data/installation/${elevateId}/data`;

export const elevateLogin = () => {
  return verticalFetch(methodGet, proxyAuthURL);
};

export const elevateGetInstallations = (token: string, cookie: string) => {
  return verticalFetch(methodGet, proxyInstallationsURL);
};

export const elevateGetData = (installationId: string) => {
  if (isDev) {
    return new Promise((resolve, reject) => {
      resolve({
        elevate_data: [
          {
            id_elevate: "1",
            online: true,
            status: "SM_WAIT",
            current_floor: 2,
            engine_status: "LIFT_ENGINE_DOWN",
            busy: true,
            overload: false,
            out_of_order: false,
            blocking_err: "ERRFS_NIHIL",
            door_status: [
              "DOOR_POSITION_CLOSED",
              "DOOR_POSITION_OPEN",
              "DOOR_POSITION_UNKNOWN",
            ],
            alive_datetime: "2024-06-27 15:06:53",
            status_datetime: "2024-06-27 15:07:00",
            created_at: "2024-06-27T13:07:00.527726Z",
            deleted_at: null,
          },
          {
            id_elevate: "1",
            online: true,
            status: "SM_MOVELIFT",
            current_floor: 0,
            engine_status: "LIFT_ENGINE_UP",
            busy: true,
            overload: false,
            out_of_order: false,
            blocking_err: "ERRFS_NIHIL",
            door_status: [
              "DOOR_POSITION_CLOSED",
              "DOOR_POSITION_CLOSED",
              "DOOR_POSITION_UNKNOWN",
            ],
            alive_datetime: "2024-06-27 15:06:24",
            status_datetime: "2024-06-27 15:06:29",
            created_at: "2024-06-27T13:06:30.432684Z",
            deleted_at: null,
          },
          {
            id_elevate: "1",
            online: true,
            status: "SM_WAIT",
            current_floor: 0,
            engine_status: "LIFT_ENGINE_DOWN",
            busy: false,
            overload: false,
            out_of_order: false,
            blocking_err: "ERRFS_NIHIL",
            door_status: [
              "DOOR_POSITION_CLOSED",
              "DOOR_POSITION_CLOSED",
              "DOOR_POSITION_UNKNOWN",
            ],
            alive_datetime: "2024-06-27 14:58:24",
            status_datetime: "2024-06-27 14:04:34",
            created_at: "2024-06-27T12:58:30.406917Z",
            deleted_at: null,
          },
        ],
        tot: 3,
      });
    });
  }

  return verticalFetch(methodGet, elevateDataURL(installationId));
};
