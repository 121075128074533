import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Collapse, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { configurationUrl, createElevatorUrl, mainUrl } from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomSelect from "../../custom/CustomSelect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import { useSnackbar } from "notistack";
import { isValidIPv4, isValidSSID, isValidWPA2 } from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import CustomDivider from "../../custom/CustomDivider";
import { deleteElevator } from "../../../api/services/elevatorService";

type ConfigurationParamsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const ConfigurationParams: React.FC<ConfigurationParamsProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device-id
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("firmwareParameters")}
      </Typography>,
    ]);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [values, setValues] = useState({
    fw: "",
    th1: "9000",
    th2: "600",
    ch1_on: "",
    ch2_on: "",
    ch3_on: "",
    apn: "iot.secure",
    speed: "1",
  });

  const [wifi, setWifi] = useState<any>({
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "Default",
          pwd: "Default123",
          dhcp: true,
          ip: "",
          mask: "",
          gateway: "",
          dns: "",
        },
      },
    ],
  });

  const [eth, setEth] = useState<any>({
    IFCS: [
      {
        ifc_name: "eth",
        ifc_params: {
          dhcp: true,
          ip: "",
          mask: "",
          gateway: "",
          dns: "",
        },
      },
    ],
  });

  const handleSubmit = () => {
    console.log("SETITEM");
    localStorage.setItem(
      "zerynth_configuration",
      JSON.stringify({ ...values, wifi: wifi, eth: eth })
    );
  };

  const checkFields = () => {
    if (
      !(
        wifi.IFCS[0].ifc_params.ssid &&
        wifi.IFCS[0].ifc_params.pwd &&
        isValidSSID(wifi.IFCS[0].ifc_params.ssid) &&
        isValidWPA2(wifi.IFCS[0].ifc_params.pwd) &&
        (wifi.IFCS[0].ifc_params.dhcp ||
          (wifi.IFCS[0].ifc_params.ip &&
            wifi.IFCS[0].ifc_params.subnetMask &&
            wifi.IFCS[0].ifc_params.gateway &&
            wifi.IFCS[0].ifc_params.dns &&
            isValidIPv4(wifi.IFCS[0].ifc_params.ip) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.subnetMask) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.gateway) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.dns))) &&
        (eth.IFCS[0].ifc_params.dhcp ||
          (eth.IFCS[0].ifc_params.ip &&
            eth.IFCS[0].ifc_params.subnetMask &&
            eth.IFCS[0].ifc_params.gateway &&
            eth.IFCS[0].ifc_params.dns &&
            isValidIPv4(eth.IFCS[0].ifc_params.ip) &&
            isValidIPv4(eth.IFCS[0].ifc_params.subnetMask) &&
            isValidIPv4(eth.IFCS[0].ifc_params.gateway) &&
            isValidIPv4(eth.IFCS[0].ifc_params.dns)))
      )
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      const tmp = localStorage.getItem("zerynth_configuration");
      console.log("TMP", tmp);
      if (!tmp) {
        //quando si esce da questa pagina si controlla se è statop salvato il file di configurazione
        // - se non è stato salvato si cancella l'elevator perche vuol dire che stiamo lasciando la pagina senza configurare veramente un device
        // - se è stato salvato vuol dire che si sta per configurare un device e quindi non si cancella
        localStorage.setItem("zerynth_deleting_elevator", "true");
        await deleteElevator(id!);
        localStorage.removeItem("zerynth_deleting_elevator");
      }
    };

    return () => {
      fetchData();
    };
  }, [id]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("firmwareParameters")}`}
          goBack={() => {
            deleteElevator(id!);
            navigate(createElevatorUrl);
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("maximumEnginePower")}
                  type="number"
                  value={values.th1}
                  handleChange={(e: any) => {
                    setValues({ ...values, th1: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("standbyPowerLimit")}
                  type="number"
                  value={values.th2}
                  handleChange={(e: any) => {
                    setValues({ ...values, th2: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("switchingContactor")}`}
                  value={values.ch1_on}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      ch1_on: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("doorOpeningContactor")}`}
                  value={values.ch2_on}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      ch2_on: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("doorClosingContactor")}`}
                  value={values.ch3_on}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      ch3_on: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("averageSpeed")}
                  type="number"
                  value={values.speed}
                  handleChange={(e: any) => {
                    setValues({ ...values, speed: e.target.value });
                  }}
                  endAdornment="m/s"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDivider label={`${t("wifi")}`} textAlign="center" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("networkSsid")}
                  value={wifi.IFCS[0].ifc_params.ssid}
                  handleChange={(e) =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            ssid: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!wifi.IFCS[0].ifc_params.ssid &&
                      !isValidSSID(wifi.IFCS[0].ifc_params.ssid),
                    helperText: `${t("invalidSsid")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("networkPassword")}
                  value={wifi.IFCS[0].ifc_params.pwd}
                  handleChange={(e) =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            pwd: e.target.value,
                          },
                        },
                      ],
                    })
                  }
                  validator={{
                    error:
                      !!wifi.IFCS[0].ifc_params.pwd &&
                      !isValidWPA2(wifi.IFCS[0].ifc_params.pwd),
                    helperText: `${t("invalidPassword")}`,
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <CustomIconButton
                      type="outlined"
                      icon={
                        showPassword ? (
                          <VisibilityOff style={{ fontSize: "18px" }} />
                        ) : (
                          <Visibility style={{ fontSize: "18px" }} />
                        )
                      }
                      onClick={handleShowPassword}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText label={`${t("ipv4")}`} type="h6" />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  checked={!wifi.IFCS[0].ifc_params.dhcp}
                  onChange={() =>
                    setWifi({
                      ...wifi,
                      IFCS: [
                        {
                          ...wifi.IFCS[0],
                          ifc_params: {
                            ...wifi.IFCS[0].ifc_params,
                            dhcp: !wifi.IFCS[0].ifc_params.dhcp,
                          },
                        },
                      ],
                    })
                  }
                />
                {!wifi.IFCS[0].ifc_params.dhcp
                  ? " " + t("manual")
                  : " " + t("automaticDhcp")}
              </Grid>
              <Grid item>
                <Collapse
                  in={!wifi.IFCS[0].ifc_params.dhcp}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("ipAddress")}
                        value={wifi.IFCS[0].ifc_params.ip}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  ip: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.ip &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.ip),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("subnetMask")}
                        value={wifi.IFCS[0].ifc_params.subnetMask}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  mask: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.subnetMask &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.subnetMask),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("gateway")}
                        value={wifi.IFCS[0].ifc_params.gateway}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  gateway: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.gateway &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.gateway),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        label={t("dns")}
                        value={wifi.IFCS[0].ifc_params.dns}
                        handleChange={(e) =>
                          setWifi({
                            ...wifi,
                            IFCS: [
                              {
                                ...wifi.IFCS[0],
                                ifc_params: {
                                  ...wifi.IFCS[0].ifc_params,
                                  dns: e.target.value,
                                },
                              },
                            ],
                          })
                        }
                        validator={{
                          error:
                            !!wifi.IFCS[0].ifc_params.dns &&
                            !isValidIPv4(wifi.IFCS[0].ifc_params.dns),
                          helperText: `${t("invalidIpv4Address")}`,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    confirm
                    fullWidth
                    label={t("cancel")}
                    onClick={() => {
                      setWifi({
                        IFCS: [
                          {
                            ifc_name: "wifi",
                            ifc_params: {
                              ssid: "Default",
                              pwd: "Default123",
                              dhcp: true,
                              ip: "",
                              mask: "",
                              gateway: "",
                              dns: "",
                            },
                          },
                        ],
                      });
                      setEth({
                        IFCS: [
                          {
                            ifc_name: "eth",
                            ifc_params: {
                              dhcp: true,
                              ip: "",
                              mask: "",
                              gateway: "",
                              dns: "",
                            },
                          },
                        ],
                      });
                      setValues({
                        fw: "",
                        th1: "",
                        th2: "",
                        ch1_on: "",
                        ch2_on: "",
                        ch3_on: "",
                        apn: "iot.secure",
                        speed: "",
                      });
                      deleteElevator(id!);
                      navigate(createElevatorUrl);
                    }}
                    type="outlined"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("ok")}
                    onClick={() => {
                      const correctFields = checkFields();
                      if (
                        correctFields &&
                        values.th1 &&
                        values.th2 &&
                        values.ch1_on &&
                        values.ch2_on &&
                        values.ch3_on &&
                        values.speed
                      ) {
                        handleSubmit();
                        navigate(configurationUrl(id!));
                      } else {
                        if (!correctFields) {
                          enqueueSnackbar(t("invalidFields"), {
                            variant: "error",
                          });
                        } else {
                          enqueueSnackbar(t("completeAllFields"), {
                            variant: "error",
                          });
                        }
                      }
                    }}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationParams;
